.chambers-search-inputs {
     display: flex;
     flex-wrap: wrap;
}
.site-search-inputs {
     display: flex;
     flex-wrap: wrap;
     width: 95%;
     gap: 1.4rem;

}

.chambers-search-inputs::after {
     content: "";
     flex-basis: 100%;
     height: 0;
}


.chambers-search-inputs div {
     flex-basis: calc((100% - 20px) / 5);
     margin-right: 20px;
     margin-bottom: 20px;
}

.Select-Icon{
     width: 100%;
     height: 56px;
}

.select-input-icon{
     position: relative;
     width: 18px;
     height: 22px;
     margin: -39px 0 17px 19px;
     z-index: 0;
     /*z-index: 1;*/
}
.select-input-Line{
     position: absolute;
     width: 5px;
     height: 42px;
     margin-top: -68px;
     margin-left: 261px;
}



.statusTableDivActive{

     box-sizing: border-box;

     /* Auto layout */

     display: flex;
     flex-direction: row;
     align-items: center;
     /*width: 146px;*/
     /*height: 31px;*/


     /*background: #F6F6F6;*/
     /*border: 1px solid #45B12A;*/
     border-radius: 10px;

     /* Inside auto layout */

     flex: none;
     order: 0;
     flex-grow: 0;
}
.statusTableDivTextActive{

     width: 57px;
     height: 23px;

     /* table title */

     font-family: 'Roboto';
     font-style: normal;
     font-weight: 600;
     font-size: 1.125rem;
     /*line-height: 23px;*/
     text-align: left;

     /*color: #45B12A;*/
     color: #3B8B81;


     /* Inside auto layout */

     flex: none;
     order: 0;
     flex-grow: 0;
}


.statusTableDivOnHold{

     box-sizing: border-box;
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     border-radius: 10px;
     flex: none;
     order: 0;
     flex-grow: 0;
}
.statusTableDivTextOnHold{

     height: 23px;
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 600;
     font-size: 1.125rem;
     text-align: left;
     color: #E0A305;
     flex: none;
     order: 0;
     flex-grow: 0;
}
.statusTableDivTextOnHoldWithOrder{

     height: 23px;
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 600;
     font-size: 1.125rem;
     text-align: left;
     color: rgba(39, 0, 92, 1);
     flex: none;
     order: 0;
     flex-grow: 0;
     max-width: 100px;
     overflow: hidden;
     text-overflow: ellipsis;

}

.statusTableDivDisable{

     box-sizing: border-box;

     /* Auto layout */

     display: flex;
     flex-direction: row;

     align-items: center;
     /*width: 9.125rem;*/
     /*height: 1.938rem;*/

     /*background: #F6F6F6;*/
     /*border: 1px solid #ACAEB1;*/
     border-radius: 10px;

     /* Inside auto layout */

     flex: none;
     order: 0;
     flex-grow: 0;
}
.statusTableDivTextDisable{

     width: 67px;
     height: 23px;

     /* table title */

     font-family: 'Roboto';
     font-style: normal;
     font-weight: 600;
     font-size: 1.125rem;
     text-align: left;
     color: #ACAEB1;


     /* Inside auto layout */

     flex: none;
     order: 0;
     flex-grow: 0;
}
